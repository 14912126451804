<template>
  <div class="service-container">
    <div class="g-section">
      <div class="service-banner">
        <div class="news-tips">
          <div>新闻公告</div>
          <div class="news-tips-en">NEWS</div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.service-container {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  flex: 1;
}
.service-banner {
  display: flex;
  width: 100%;
  height: 160px;
  background-color: #d3dfe8;
  background-image: url("~@/assets/images/banner/information.png");
  justify-content: center;
  align-items: center;
  .news-tips {
    width: 1100px;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 45px;
    &-en {
      font-size: 20px;
      font-family: HelveticaNeue;
      color: rgba(255, 255, 255, 0.35);
      line-height: 24px;
    }
  }
}
.g-section {
  background-color: #f6f6f8;
}
</style>
